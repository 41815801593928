<template>
  <b-row>
    <b-col cols="12">
      <b-card-code title="">
        <b-row class="mb-1">
          <b-col md="6" />
          <b-col md="3">
            <label for="">
              Người truy cập
            </label>
            <b-form-select
              v-model="filter.admin_id"
              :options="adminOption"
              @change="loadItem"
            />
          </b-col>
          <b-col md="3">
            <label for="">
              Thời gian
            </label>
            <b-form-datepicker
              v-model="filter.time"
              local="en"
              placeholder="Thời gian"
              class="mb-1"
              selected-variant="primary"
              @input="loadItem"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
            >
              <thead>
                <tr>
                  <th scope="col">
                    #
                  </th>
                  <th
                    scope="col"
                    width="500"
                  >
                    Log
                  </th>
                  <th
                    scope="col"
                    width="200"
                  >
                    Tạo lúc
                  </th>
                  <th
                    scope="col"
                    width="150"
                    class="text-center"
                  >
                    IP
                  </th>
                  <th
                    scope="col"
                    width="200"
                    class="text-center"
                  >
                    Người dùng
                  </th>
                  <th scope="col">
                    Thiết bị
                  </th>
                </tr>
              </thead>
              <template v-if="rows.length">
                <template v-if="isLoading">
                  <tr
                    v-for="(item) in rows"
                  >
                    <td colspan="1">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="3">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="2">
                      <b-skeleton animation="fade" />
                    </td>
                  </tr>
                </template>
                <tr
                  v-for="(item, index) in rows"
                  v-else
                  :id="item.id"
                  :key="item.id"
                >
                  <td scope="row">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <div v-html="item.note" />
                  </td>
                  <td>
                    {{ item.created }}
                  </td>
                  <td class="text-center">
                    {{ item.ip }}
                  </td>
                  <td class="text-center">
                    {{ item.user ? item.user.name: '' }}
                  </td>
                  <td>
                    {{ item.user_agent }}
                  </td>
                </tr>
              </template>
              <tr
                v-else
              >
                <td colspan="9">
                  <p class="text-center">
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
              <!--              </draggable>-->
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPagination,
  BFormCheckbox,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BSkeleton,
  BFormFile,
  BFormDatepicker,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import config from '@/config'

export default {
  components: {
    BCardCode,
    BFormDatepicker,
    ValidationObserver,
    ValidationProvider,
    BSkeleton,
    BRow,
    BCol,
    BFormFile,
    draggable,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BPagination,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      pageLength: 200,
      adminOption: [{ value: 'all', text: this.$t('all') }],
      filter: {
        admin_id: null,
        time: null,
      },
      total: 0,
      rows: [],
      isLoading: false,
    }
  },

  created() {
    this.loadItem()
    this.loadAdmin()
  },
  methods: {
    loadItem() {
      this.isLoading = true
      this.$http.get('/user-activity', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          user_id: this.filter.admin_id,
          time: this.filter.time,
        },
      })
        .then(res => {
          this.total = res.data.total
          this.rows = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    loadAdmin() {
      this.isLoading = true
      this.$http.get('/accounts', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          is_admin: 1,
        },
      })
        .then(res => {
          const { data } = res.data
          data.map(val => {
            this.adminOption.push({
              value: val.id,
              text: val.name,
            })
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    convertDate(iosDate) {
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItem()
    },
    // changeFilterAdmin(val) {
    //   this.filter.admin_id = val
    //   this.loadItem()
    // },
    // changeTimeLog() {
    //   console.log(1)
    // },
  },
}
</script>
